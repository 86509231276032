<template>
    <div class="p-2 md:p-3" :class="name == 'filter[jobTypeId]' ? 'flex-1' : 'w-full xs:w-1/2 lg:w-full'" v-if="(options.length > 1 || options[0].id!=null)">
        <SelectBox
            :options="options"
            :placeholder="$attrs['placeholder']"
            :label="uiLabel"
            v-model="selectedOption"
        ></SelectBox>
        <input type="hidden" :name="name" :value="inputValue"/>
    </div>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import SelectBox from './SelectBox.vue'

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    dependent: {
        type: String
    },
    options: {
        type: Array,
        required: true
    },
    selected: {
        type: [Object, Array], // Object if the value is found. An empty array if the value is not found.
        required: true
    },
    valueLabel: {
        type: String,
        required: true
    },
    uiLabel: {
        type: String,
        required: true
    }
})

const { getFieldValue, setFieldValue, initialise } = inject('form-tree')

const selectedOption = computed({
    get: () => getFieldValue(props.name),
    set: (val) => setFieldValue(props.name, val)
})

const inputValue = computed(() => selectedOption.value ? selectedOption.value[props.valueLabel] : '')

initialise({
    name: props.name,
    value: Array.isArray(props.selected) ? null : props.selected,
    dependent: props.dependent || null
})

</script>