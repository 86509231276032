<template>
    <button class="pill mr-2 mb-2" v-if="selected.name" @click="selectedOption = null">{{selected.name}} <font-awesome-icon :icon="['far', 'times-circle']" class="ml-1" /></button>
</template>

<script setup>
import { inject, computed } from 'vue'
 
const props = defineProps({
    name: {
        type: String,
        required: true
    },
    dependent: {
        type: String
    },
    selected: {
        type: [Object, Array], // Object if the value is found. An empty array if the value is not found.
        required: true
    }
})

const { getFieldValue, setFieldValue, initialise } = inject('form-tree')

const selectedOption = computed({
    get: () => getFieldValue(props.name),
    set: (val) => setFieldValue(props.name, val)
})

</script>