<template>
        <div class="selectBox relative cursor-text" ref="selectBoxRef">
        <Combobox v-model="selectedOption">
            <div class="flex pl-5 pb-2 pt-1 relative border-2 border-primary rounded-lg" :class="{ 'rounded-b-none border-b-0' : isToggled }">
                <span @click="openSelectBox" v-if="selectedOption && !query" class="selectBox__selected" :class="{ 'absolute opacity-40' : isToggled }">{{selectedOption ? selectedOption[label] : ''}}</span>
                <ComboboxInput
                    @change="query = $event.target.value"
                    :placeholder="selectedOption ? '' : placeholder"
                    autocomplete="off"
                    class="selectBox__input"
                    ref="comboInput"
                    @click="openSelectBox"
                />
                <div class="selectBox__actions">
                    <button v-if="selectedOption && !isToggled" @click="clearOption" type="button"><font-awesome-icon icon="times" class="text-base text-primary-darker mr-2"/></button>
                    <ComboboxButton @click="isToggled = !isToggled" class="flex">
                        <font-awesome-icon :class="{ 'rotate-180' : isToggled }" icon="fa-chevron-down" aria-hidden="true" class="text-base text-primary-darker duration-75 ease-in"/>
                    </ComboboxButton>
                </div>
            </div>
            <ComboboxOptions static v-if="isToggled" class="absolute bg-white w-full rounded-b border-2 border-t-0 border-primary z-50 max-h-[350px] overflow-auto" :class="{ 'border-t-[1px]' : isToggled }">
                <div v-if="filteredOptions.length === 0 && query !== ''" class="px-4 py-2 relative cursor-default select-none text-gray-700">
                    Nothing found.
                </div>
                <ComboboxOption static v-slot="{ active }" v-for="option in filteredOptions" :key="option[label]" :value="option">
                    <span class="block cursor-pointer px-4 py-2 border-b border-slate-200 whitespace-nowrap"
                    :class="{ 'text-primary bg-slate-200 border-primary' : active }">{{ option[label] }}</span>
                </ComboboxOption>
            </ComboboxOptions>
        </Combobox>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeMount, watch } from "vue";
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  ComboboxButton
} from "@headlessui/vue";

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  label: {
    type: String,
    default: "name"
  },
  placeholder: {
      type: String,
      required: true
  },
  modelValue: {
      validator: (val) => typeof val === 'object' || val === null,
      required: true
  }
});

const emit = defineEmits(['update:modelValue'])

const query = ref("")
const isToggled = ref(false)
const selectBoxRef = ref(null)
const comboInput = ref(null)

const selectedOption = computed({
    get: () => props.modelValue,
    set: (val) => emit('update:modelValue', val)
})

const filteredOptions = computed(() =>
  query.value === ""
    ? props.options
    : props.options.filter(option => {
        if (option.hasOwnProperty(props.label) && option[props.label]) {
            return option[props.label].toString().toLowerCase().indexOf(query.value.toLowerCase()) !== -1
        }
      })
);

function openSelectBox() {
    isToggled.value = true
    comboInput.value.el.focus()
}

function clearOption() {
    isToggled.value = false
    selectedOption.value = null
    query.value = ''
    emit('update:modelValue', null)
}

function isNumeric(str) {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

function selectOption(val) {
    isToggled.value = false
    emit('update:modelValue', val ? val : null)
}

function useClickOutside(ref, callback) {
    if (!ref) return

    let listener = e => {
        if (e.target == ref.value || e.composedPath().includes(ref.value)) return

        if (typeof callback == 'function') callback()
    }

    onMounted(() => {
        window.addEventListener('click', listener)
    })

    onBeforeMount(() => {
        window.removeEventListener('click', listener)
    })
}

useClickOutside(selectBoxRef, () => {
    query.value = ''
    isToggled.value = false
})

</script>

<style lang="pcss">
    .selectBox {
        min-width: 200px;

        &__input {
            @apply w-0 max-w-full flex-grow appearance-none outline-none rounded-lg mt-1;
            font-size: 1em;
            color: #333;
            &::placeholder {
                @apply text-gray-700;
                font-size: 1em;
                color: black;
            }
        }

        &__actions {
            @apply flex;
            padding: 5px 16px 0px 3px;
        }

        &__selected {
            @apply flex items-center mt-1;
            font-size: 1em;
            color: #333;
        }
    }
</style>