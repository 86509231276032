<template>
    <form method="POST">
        <input type="hidden" name="action" value="commerce/cart/update-cart" />
        <input type="hidden" name="purchasableId" :value="selectedId" />
        <input type="hidden" :name="csrfName" :value="csrfToken" />
        <input type="hidden" name="qty" v-model="qtyInput" />
        <slot name="before"></slot>
        <div class="md:flex w-full">
            <div class="md:w-1/2">
                <img :src="selectedProduct.image.url" :alt="selectedProduct.image.title" />
            </div>
            <div class="flex-1">
                <h1 class="md:mb-[18px] md:text-left text-center title-underline md:after:left-0 md:after:transform-none">{{
                    product.title
                }}
                </h1>
                <div class="flex -mr-2">
                    <template v-for="variant in product.variants">
                        <div v-if="product.variants.length > 1 || variant.icon" v-on:click="selectedId = variant.id"
                            class="md:w-28 border rounded border-primary-dark p-3 mr-2 cursor-pointer w-1/2 text-center text-xs mt-2"
                            :class="[
                                selectedId == variant.id
                                    ? 'bg-primary-dark text-white'
                                    : 'text-primary-darker',
                            ]">
                            <div v-if="variant.icon" class="fill-current w-20 mx-auto" v-html="variant.icon"></div>
                            {{ variant.title }}
                        </div>
                    </template>
                </div>
                <div class="flex py-4">
                    <div class="w-20 pr-4">
                        <input
                            class="text-center text-primary-darker font-bold border border-primary-darker p-1 xs:p-2 xs:pt-1 rounded w-full text-lg xs:text-2xl"
                            type="number" min="1" v-model="qtyInput" @change="validateInput" />
                    </div>
                    <h4 class="pt-2">{{ currencyGBP(selectedPrice) }} + VAT</h4>
                </div>
                <button class="btn w-full text-lg xs:text-xl btn--product block" type="submit">
                    Buy Now
                </button>
            </div>
        </div>
    </form>
</template>
  
<script setup>
import { ref, computed, watch } from "vue";

const props = defineProps({
    product: Object,
    selected: Number,
});

const selectedId = ref(null);
const selectedPrice = ref(null);
const qtyInput = ref(1);

function validateInput() {
    qtyInput.value = qtyInput.value <= 0 ? 1 : qtyInput.value;
}

function currencyGBP(price) {
    const formatter = new Intl.NumberFormat("en-GB", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
    });
    return formatter.format(price)
}

const csrfName = computed(() => {
    return window.csrfTokenName || "";
});

const csrfToken = computed(() => {
    return window.csrfTokenValue || "";
});

const selectedProduct = computed(() => {
    return props.product.variants.filter((variant) => {
        return variant.id == selectedId.value;
    })[0];
});

selectedId.value = props.selected;
selectedPrice.value = selectedProduct.value.price;

watch(qtyInput, (newQtyInput) => {
    selectedPrice.value = newQtyInput ? selectedProduct.value.price * newQtyInput : 0.0
})

watch(selectedId, (newSelectedId) => {
    selectedPrice.value = selectedProduct.value.price * qtyInput.value
})
</script>
