<template>
  <div class="vue-select select-none">
        <form ref="filterForm" class="filterForm" action="/application-list#application-list-content">
            <div class="text-primary-darker md:hidden border-2 border-l-0 rounded-r-lg inline p-2 border-primary-darker " @click="openMobileView" v-if="!mobileView">
                <font-awesome-icon icon="filter" />
            </div>
            <slot name="dependents"></slot>
        </form>
    </div>
</template>

<script setup>
import { ref, reactive, provide, computed } from 'vue'

const mobileView = ref(false)
const filterForm = ref(null)
const formState = ref([])
const dependencyMap = ref({})
const clearedNames = ref([])

const emit = defineEmits(['updated', 'openMobile'])

function openMobileView() {
    mobileView.value = !mobileView.value
    emit('openMobile', mobileView.value)
}

function getFieldValue(name) {
    return formState.value.find(({ fieldName }) => fieldName === name).fieldValue
}

function setFieldValue(name, value) {
    const formStateCopy = formState.value.slice()
    for (let i = 0; i < formStateCopy.length; i++) {
        if (formStateCopy[i].fieldName === name) {
            formStateCopy[i].fieldValue = value
            break
        }
    }

    resetDependencyValues(dependencyMap.value[name], formStateCopy)
    clearedNames.value = []
    formState.value = formStateCopy
    // // TODO - Pass function via prop or update via AJAX
    emit('updated', filterForm.value)
}

function resetDependencyValues(name, state) {
    if (!name || clearedNames.value.includes(name)) return
    for (let i = 0; i < state.length; i++) {
        if (state[i].fieldName === name) {
            state[i].fieldValue = null
            break
        }
    }
    clearedNames.value.push(name)
    resetDependencyValues(dependencyMap.value[name], state)
}

// Only runs when a dependency child runs for the first time
function initialise({name, value, dependent}) {
    if (formState.value.some(val => val.name === name)) {
        console.error(`${name} has already been initialised on the form state.`)
    }
    formState.value.push({ fieldName: name, fieldValue: value }) 

    if (dependencyMap.value.hasOwnProperty(name)) {
        console.warn(`${name} has already been initialised with a dependency value of ${dependencyMap.value[name]}`)
    }
    dependencyMap.value[name] = dependent
}

provide('form-tree', {
    getFieldValue,
    setFieldValue,
    initialise
})

</script>