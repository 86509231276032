import { createApp } from 'vue'
import components from "./components/_components.js";

import "./app.css";

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTimesCircle as farTimesCircle, faCheckCircle as farCheckCircle, faTrashAlt, faEdit } from '@fortawesome/free-regular-svg-icons'
import { faFilter, faBars, faUserSecret, faMinus, faPlus, faTimesCircle, faCheckCircle, faExclamationTriangle, faCheck, faExclamationCircle, faShoppingBasket, faTimes, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTag, faLocationDot, faEnvelope, faPhone} from '@fortawesome/free-solid-svg-icons'
import { faFacebook, faTwitter, faLinkedin, faLinkedinIn, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(farTimesCircle, farCheckCircle, faEdit)
library.add(faFacebook, faTwitter, faLinkedin, faLinkedinIn, faFacebookF)
library.add(faFilter, faBars, faMinus,faUserSecret,faPlus,faTimesCircle, faCheckCircle, faExclamationTriangle, faCheck, faExclamationCircle, faTrashAlt, faShoppingBasket, faTimes, faChevronDown, faChevronUp, faChevronLeft, faChevronRight, faTag, faLocationDot, faEnvelope, faPhone)

const appConfig = {
    compilerOptions: {
        delimiters: ["${", "}"],
        whitespace: 'preserve'
    },
    components
}

const app = createApp(appConfig)

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')