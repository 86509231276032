<template>
  <div class="flex flex-col">
        <div class="mb-2 md:mb-0" v-bind:class="{block : discount, 'md:flex' : !discount}">
            <div v-if="!cartData.couponCode" class="hidden md:block">
                <button class="voucher-btn voucher-btn--gray focus:outline-none" @click="toggle()">
                    <div class="voucher-btn__svg voucher-btn__svg--desktop">
                        <span class="hidden md:inline-block">
                            <font-awesome-icon v-if="isToggled" icon="chevron-left" />
                            <font-awesome-icon v-else icon="chevron-right" />
                        </span>
                        <span class="md:hidden">
                            <font-awesome-icon v-if="isToggled" icon="chevron-up" />
                            <font-awesome-icon v-else icon="chevron-down" />
                        </span>
                    </div>
                    <div class="voucher-btn__text">{{ btnText }}</div>
                </button>
            </div>

            <button v-if="!cartData.couponCode" class="md:hidden flex items-center border-b mb-4 ml-auto focus:outline-none focus:border-solid hover:border-solid active:border-solid" :class="{ 'border-dashed' : !isToggled }" @click="toggle()">
                <div class="voucher-btn__svg">
                    <span class="hidden md:inline-block">
                        <font-awesome-icon v-if="isToggled" icon="chevron-left" />
                        <font-awesome-icon v-else icon="chevron-right" />
                    </span>
                    <span class="md:hidden">
                        <font-awesome-icon v-if="isToggled" icon="chevron-up" />
                        <font-awesome-icon v-else icon="chevron-down" />
                    </span>
                </div>
                <div class="voucher-btn__text">{{ btnText }}</div>
            </button>

            <form method="POST" v-if="isToggled" class="block justify-center items-center xs:flex mb-4 md:mb-0">
                <input type="hidden" :name="csrfName" :value="csrfToken">
                <input type="hidden" name="action" value="commerce/cart/update-cart">
                <input type="hidden" name="successMessage" :value="successMessageHash">
                <input type="hidden" name="clearNotices">
                <template v-if="shouldShow || !cartData.couponCode">
                    <input type="text" v-model="inputText" name="couponCode" class="voucher-btn__input  bg-white border text-lg border-gray-400 h-10 md:h-12 p-3 md:w-1/2 lg:w-auto w-full mb-4 ml-0 mr-0 xs:mr-4 md:mx-4 xs:mb-0" placeholder="Enter voucher code">
                    <button type="submit" class="voucher-btn voucher-btn--orange focus:outline-none" :class="{ 'opacity-50' : !inputText, 'pointer-events-none' : !inputText }" :disabled="disableBtn">
                        <div class="voucher-btn__svg voucher-btn__svg--desktop"><font-awesome-icon icon="tag" /></div>
                        <div class="voucher-btn__text">{{ inputText ? 'Use Code' : 'Please Enter Code'}}</div>
                    </button>
                </template>
            </form>
        </div> 
        <p v-if="notice && isToggled" class="mb-6 md:mt-6 bg-red-100 border border-red-500 text-lg text-center text-red-500 py-2">{{ notice }}</p>
    </div>
</template>

<script setup>
import { ref, computed } from 'vue'

const props = defineProps({
  cartData: {
    type: Object,
    required: true
  },
    notice: {
      type: String,
    },
    shouldShow: {
      type: Boolean,
      required: true,
    },
    successMessageHash: {
      type: String,
      required: true,
    },
    discount: {
      type: Boolean,
      default: false,
    },
})

const isToggled = ref(false)
const btnText = ref("Got a voucher?")
const inputText = ref("")

isToggled.value = props.shouldShow ? true : false
btnText.value = isToggled.value ? "Don't have a voucher" : 'Got a voucher?'

function toggle() {
  isToggled.value = !isToggled.value
  btnText.value = isToggled.value ? "Don't have a voucher" : 'Got a voucher?'
}

const csrfName = computed(() => {
  return window.csrfTokenName || ""
})

const csrfToken = computed(() => {
  return window.csrfTokenValue || ""
})

const disableBtn = computed(() => {
  return !inputText.value ? true : false;
})

</script>