<template>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="true"
      loader="spinner"
      color="#ef7d00"
      aria-busy="null"
    ></loading>

    <div v-if="basketItems.length < 1">
      <div class="mt-8 text-lg text-center">
        <p>
          The items that you add to your basket will be displayed here.
        </p>
        <p>
          You can add items from the <a class="underline text-primary" href="/shop">products page</a>.
        </p>
      </div>
      <div class="py-5 mx-5">
        <div class="text-center">
          <a href="/shop">
            <button class="w-1/2 btn">Shop Now</button>
          </a>
        </div>
      </div>
    </div>

    <div v-else class="mt-10">
      <div class="justify-between hidden px-6 text-2xl md:flex lg:pr-16 lg:pl-8">
        <div class="flex-auto"><p>Product</p></div>
        <div
          class="flex justify-between pl-3 mb-1 md:w-3/6 lg:w-2/5"
          :class="{
            'justify-evenly': summaryPage,
            'justify-between': !summaryPage,
          }"
        >
          <div class="ml-3 lg:ml-6"><p>Qty</p></div>
          <div class="ml-6"><p>Price</p></div>
          <div class="w-1/12" v-if="!summaryPage"></div>
        </div>
      </div>
      <template v-for="item in basketItems">
        <div
          class="px-3 py-4 border-2 rounded-lg rounded-bl-none rounded-br-none border-primary lg:px-16 xs:px-6 md:rounded-bl-lg md:rounded-br-lg"
        >
          <div class="container items-center block md:flex">
            <div
              class="flex flex-col items-center flex-auto h-auto mb-10 md:h-28 md:flex-row md:mb-0"
            >
              <div>
                <img
                  :src="item.image.url"
                  :alt="item.image.title"
                  class="w-64 m-auto md:w-28 lg:mr-10 md:mr-4"
                />
              </div>
              <div class="mt-6 text-center md:mt-0 md:text-left">
                <p class="p-0 text-2xl">{{ item.title }}</p>
                <p class="p-0 text-xl text-orange-300">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <div
              class="flex items-center md:w-3/6 lg:w-2/5"
              :class="{
                'justify-around': summaryPage,
                'justify-between': !summaryPage,
              }"
            >
              <form
                @submit.prevent
                v-if="!summaryPage"
                class="flex items-center justify-center order-2 w-24 mr-0 standard md:order-1 xs:mr-12 md:mr-0"
              >
                <button
                  class="text-sm cursor-pointer text-primary xs:text-lg"
                  type="button"
                  @click.prevent="decreaseQty(item.id)"
                  title="Decrease Item Quantity"
                >
                  <font-awesome-icon icon="minus" />
                </button>
                <input
                  type="number"
                  :value="lineItemQty(item.id)"
                  :name="'lineItems[' + item.id + '][qty]'"
                  class="mx-2 text-center input-appearance"
                  @change="setLineItemQty($event, item.id)"
                />
                <button
                  class="text-sm cursor-pointer text-primary xs:text-lg"
                  type="button"
                  @click.prevent="increaseQty(item.id)"
                  title="Increase Item Quantity"
                >
                  <font-awesome-icon icon="plus" />
                </button>
              </form>
              <p
                v-if="summaryPage"
                class="order-2 w-20 text-2xl text-center md:text-right md:order-1 xs:mr-12 md:mr-0 mr:0"
                v-bind:class="{ 'order-1': summaryPage }"
              >
                {{ item.qty }}
                <span class="-ml-1">x</span>
              </p>
              <p
                class="order-1 text-2xl xs:text-xl"
                v-bind:class="{ 'order-2': summaryPage }"
              >
                {{ currencyGBP(item.subtotal) }}
              </p>
              <div
                class="flex order-3 text-3xl cursor-pointer hover:text-primary"
                v-if="!summaryPage"
              >
                <button
                  class="text-xl cursor-pointer xs:text-2xl"
                  type="button"
                  @click.prevent="removeItem(item.id)"
                  title="Remove Item"
                >
                  <font-awesome-icon :icon="['far', 'trash-alt']" />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-row-reverse mb-6 ">
          <div
            class="flex items-center justify-end w-full p-2 px-3 text-gray-600 border-2 border-t-0 border-primary text-md xs:w-auto"
            v-for="adjustment in item.adjustments"
          >
            <p class="p-0">
              {{ adjustment.name }} =
              <span class="whitespace-nowrap">{{
               currencyGBP(adjustment.amount)
              }}</span>
            </p>
          </div>
        </div>
      </template>
      <slot name="terms"></slot>
      <div
        class="flex-col justify-between text-right md:flex-row"
        :class="{ flex: summaryPage }"
      >
        <slot name="voucher"></slot>
        <div>
          <form v-if="basketCouponCode" method="POST">
            <input type="hidden" :name="csrfName" :value="csrfToken" />
            <input
              type="hidden"
              name="action"
              value="commerce/cart/update-cart"
            />
            <slot name="hidden-inputs"></slot>
            <input type="hidden" name="clearNotices" />
            <input type="hidden" name="couponCode" value="" />
            <button
              type="submit"
              class="h-12 px-3 mb-3 text-base leading-relaxed bg-gray-300 rounded hover:bg-primary hover:text-white focus:outline-none"
            >
              <font-awesome-icon :icon="['far', 'trash-alt']" class="mr-2" />
              Remove Voucher Code: <strong>{{ basketCouponCode }}</strong>
            </button>
          </form>
          <template v-for="(adjustment, key) in basketTotals.adjustments">
            <div
              class="mb-4 text-right"
              v-for="(adjustmentItem, key) in adjustment"
              :key="adjustmentItem.id"
            >
              <p class="p-0 text-base xs:text-xl">
                {{ adjustmentItem.name }} : {{ currencyGBP(adjustmentItem.amount) }}
              </p>
            </div>
          </template>
        </div>
      </div>
      <div class="text-right">
        <h3 class="text-2xl xs:text-3xl">
          Total: {{ currencyGBP(basketTotals.totalPrice) }}
        </h3>
      </div>
    </div>
    <div v-if="basketItems.length >= 1 && !summaryPage" class="pt-10 pb-16">
      <div class="w-full text-right">
        <a
          class="w-auto text-lg btn xs:text-xl md:w-1/3"
          href="/checkout/your-details"
        >
          Proceed to Checkout
        </a>
      </div>
    </div>
  </div>
</template>

<script setup>
import Loading from "vue-loading-overlay";
import { ref, computed, reactive } from 'vue'
import useEventsBus from '../useEventBus'

const { emit } = useEventsBus()

const basketItems = ref([...props.lineItems])
const basketTotals = reactive(Object.assign({}, props.basketTotalsData))
const isLoading = ref(false)
const basketCouponCode = ref(props.couponCode)

const props = defineProps({
  lineItems: {
      required: true,
      type: Array,
    },
    basketTotalsData: {
      required: true,
      type: Object,
    },
    summaryPage: String,
    couponCode: String
})

const csrfName = computed(() => {
  return window.csrfTokenName || "";
});

const csrfToken = computed(() => {
  return window.csrfTokenValue || "";
});

function currencyGBP(price) {
  const formatter = new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
      minimumFractionDigits: 2,
  });
  return formatter.format(price)
}

function emitBasketUpdate(cart) {
  emit('update-shopping-cart', cart.totalQty)
}

function setAdjustmentData(res) {
  const adjustmentData = res.cart.orderAdjustments.reduce(
    (obj, adjustment) => {
      obj[adjustment.type] = [adjustment];
      return obj;
    },
    {}
  );
  return adjustmentData;
}

 function findLineItem(id) {
  const lineItemIndex = basketItems.value.findIndex(item => item.id === id)
  return [basketItems.value[lineItemIndex], lineItemIndex]
}

function increaseQty(id) {
  const [lineItem, lineItemIndex] = findLineItem(id)
  lineItem.qty = lineItem.qty + 1;
  basketItems.value.splice(lineItemIndex, 1, lineItem);
  updateQty()
}

function decreaseQty(id) {
  const [lineItem, lineItemIndex] = findLineItem(id)
  if (lineItem.qty > 1) {
    lineItem.qty = lineItem.qty - 1
    basketItems.value.splice(lineItemIndex, 1, lineItem)
    updateQty()
  }
}

function lineItemQty(id) {
  const lineItem = basketItems.value.find((item) => item.id === id);
  return lineItem ? lineItem.qty : 1;
}

function setLineItemQty($event, id) {
  const [lineItem, lineItemIndex] = findLineItem(id)
  lineItem.qty = event.target.value
  basketItems.value.splice(lineItemIndex, 1, lineItem)
  updateQty()
}

async function updateQty() {
  try {
    isLoading.value = true;
    const lineItemData = basketItems.value.reduce((struct, { id, qty }) => {
      struct[id] = { qty };
      return struct
    }, {})
    const response = await fetch('/?action=commerce/cart/update-cart', {
      method: 'post',
      body: JSON.stringify({
        [csrfName.value]: csrfToken.value,
        lineItems: lineItemData,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) throw new Error(response.statusText)
    const cartResponse = await response.json()
    basketItems.value = basketItems.value.map((item) => {
      const cartResponseItem = cartResponse.cart.lineItems.find(cartResponseItem => cartResponseItem.id === item.id)
      if (cartResponseItem) {
        item.qty = cartResponseItem.qty
        item.subtotal = cartResponseItem.subtotal
        item.adjustments = cartResponseItem.adjustments
      }
      return item
    })
    basketCouponCode.value = cartResponse.cart.couponCode
    basketTotals.totalPrice = cartResponse.cart.total
    basketTotals.adjustments = setAdjustmentData(cartResponse);
    emitBasketUpdate(cartResponse.cart);
    isLoading.value = false;
  } catch (error) {
    console.error(error);
    isLoading.value = false;
  }
}

async function removeItem(id) {
  try {
    isLoading.value = true
    const [lineItem, lineItemIndex] = findLineItem(id)
    const response = await fetch('/?action=commerce/cart/update-cart', {
      method: 'post',
      body: JSON.stringify({
        [csrfName.value]: csrfToken.value,
        lineItems: { [id]: { remove: 1 } },
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    if (!response.ok) throw new Error(response.statusText)
    const cartResponse = await response.json()
    basketItems.value.splice(lineItemIndex, 1)
    basketCouponCode.value = cartResponse.cart.couponCode
    basketTotals.totalPrice = cartResponse.cart.total
    basketTotals.adjustments = setAdjustmentData(cartResponse);
    emitBasketUpdate(cartResponse.cart);
    isLoading.value = false;
  } catch (error) {
    console.error(error)
    
    isLoading.value = false
  }
}

</script>

<style>
/*! purgecss start ignore */
@import 'vue-loading-overlay/dist/vue-loading.css';
/*! purgecss end ignore */
</style>
