import AddressFields from './AddressFields.vue'
import ApplicationList from './ApplicationList.vue'
import Basket from './Basket.vue'
import Features from './Features.vue'
import MainMenu from './MainMenu.vue'
import OrderTermsAndConditions from './OrderTermsAndConditions.vue'
import PaymentModal from './PaymentModal.vue'
import Modal from './Modal.vue'
import Product from './Product.vue'
import ServiceStatusTable from './ServiceStatusTable.vue'
import VoucherCode from './VoucherCode.vue'
import ApplicationListForm from './ApplicationListForm.vue'
import LoginModal from './LoginModal.vue'

export default {
    AddressFields,
    ApplicationList,
    Basket,
    Features, 
    MainMenu,
    OrderTermsAndConditions,
    PaymentModal,
    Modal,
    Product,
    ServiceStatusTable,
    VoucherCode,
    ApplicationListForm,
    LoginModal
}