<template>
  <table class="w-full select-none border-t border-primary-darker">
    <tbody class="flex-1 md:flex-none">
      <template v-for="(issue, index) in data">
        <tr
          :class="[index % 2 ? 'odd' : 'even', { open: open == index }]"
          class="cursor-pointer"
          @click="open = open == index ? null : index"
        >
          <td class="w-4">
            <font-awesome-icon icon="plus" />
          </td>
          <td>{{ issue.title }}</td>
          <td class="w-16">
            <font-awesome-icon
              v-if="issue.icon == 'warning'"
              icon="exclamation-triangle"
              class="text-yellow-600"
              size="2x"
            />
            <font-awesome-icon
              v-else-if="issue.icon == 'error'"
              icon="exclamation-circle"
              class="text-red-600"
              size="2x"
            />
            <font-awesome-icon
              v-else-if="issue.icon == 'noIssues'"
              icon="check-circle"
              class="text-green-600"
              size="2x"
            />
          </td>
        </tr>
        <tr :class="[index % 2 ? 'odd' : 'even', { hidden: open != index }]">
          <td>&nbsp</td>
          <td colspan="2">
            <div class="text-gray-700 pb-4">
              {{
                issue.description != ""
                  ? issue.description
                  : "No Description Provided"
              }}
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import { ref } from 'vue'

const props = defineProps({
    data: Array
})

const open = ref(null)

</script>
