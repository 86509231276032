<template>
  <div class="vld-parent">
    <loading
      v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="true"
      loader="spinner"
      color="#ef7d00"
      aria-busy="null"
    ></loading>
    <form method="POST" ref="form">
      <input type="hidden" :name="csrfName" :value="csrfToken" />
      <div class="terms-and-conditions__checkbox">
        <input
          type="hidden"
          name="fields[acceptTermsAndConditions]"
          value="0"
        />
        <label class="checkbox-container">
          <input
            type="checkbox"
            name="fields[acceptTermsAndConditions]"
            id="tandcconfirm"
            value="1"
            :checked="accepted"
            @change="toggleTermsAndConditions"
          />
          <font-awesome-icon icon="check" class="checkmark" />
        </label>
        <label for="tandcconfirm">Tick here to confirm our T&C’s*</label>
      </div>
    </form>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import Loading from "vue-loading-overlay"
import useEventsBus from '../useEventBus'

const props = defineProps({
  termsAndConditions: {
    type: Boolean,
    required: true
  }
})

const form = ref(null)
const isLoading = ref(false)
const accepted = ref(props.termsAndConditions)

const { emit } = useEventsBus()

async function toggleTermsAndConditions() {
  accepted.value = !accepted.value
  emit('update-terms-and-conditions', accepted.value)
  isLoading.value = true
  if (!form) throw new Error('Something went wrong. Please reload the page and try again.')
  const formData = new FormData(form.value)
  try {
    const response = await fetch('/?action=commerce/cart/update-cart', {
      method: 'post',
      body: JSON.stringify({
        fields: {
          acceptTermsAndConditions: accepted.value
      }, 
      ...Object.fromEntries(formData)
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      }
    })
    if (!response.ok) throw new Error(response.statusText)
    const { cart } = await response.json()
    accepted.value = cart.acceptTermsAndConditions
    isLoading.value = false
  } catch (error) {
    console.error(error)
    isLoading.value = false
  }
}

const csrfName = computed(() => {
  return window.csrfTokenName || ""
})

const csrfToken = computed(() => {
  return window.csrfTokenValue || ""
})

</script>
