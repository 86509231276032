<template>
  <div>
    <fieldset class="address-form__fields">
      <h3 class="address-form__headings">Your Details</h3>
      <div class="">
        <div class="w-full md:gap-24 flex flex-col md:flex-row gap-0">
          <div class="flex gap-4 w-full md:w-1/2">
            <div
              class="address-form__input-container address-form__input-container--flex"
            >

              <label class="address-form__label" for="shippingAddress[firstName]"
                >First Name*</label
              >
              <input
                type="text"
                class="address-form__input"
                ref="customerFirstNameField"
                name="shippingAddress[firstName]"
                required
                v-model="customer.firstName"
              />
              <div
                class="address-form__error"
                v-if="shippingAddressErrors || billingAddressErrors"
              >
                {{ shippingAddressErrors.firstName }}
              </div>
            </div>
            <div
              class="address-form__input-container address-form__input-container--flex mb-0"
            >
              <label class="address-form__label" for="shippingAddress[lastName]"
                >Last Name*</label
              >
              <input
                type="text"
                class="address-form__input"
                name="shippingAddress[lastName]"
                required
                v-model="customer.lastName"
              />
              <div
                class="address-form__error"
                v-if="shippingAddressErrors || billingAddressErrors"
              >
                {{ shippingAddressErrors.lastName }}
              </div>
            </div>
              <input
                type="hidden"
                name="shippingAddress[fullName]"
                v-model="fullName"
              />
               <input
                v-if="!copyShipping"
                type="hidden"
                name="billingAddress[fullName]"
                v-model="fullName"
              />
          </div>
          <div class="md:mb-8 address-form__input-container w-full md:w-1/2">
            <label class="address-form__label" for="email"
              >Email Address*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="email"
              required
              v-model="customer.email"
            />
            <div class="address-form__error" v-if="emailAddressErrors">
              {{ emailAddressErrors }}
            </div>
          </div>
        </div>
        <div class="w-full flex md:gap-24 flex-col md:flex-row gap-0">
          <div class="md:w-1/2 w-full md:mb-0 address-form__input-container">
            <label class="address-form__label" for="businessName"
              >Company Name*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="shippingAddress[organization]"
              required
              v-model="customer.company"
            />
            <input
              v-if="!copyShipping"
              type="hidden"
              name="billingAddress[organization]"
              v-model="customer.company"
            />
            <div
              class="address-form__error"
              v-if="shippingAddressErrors || billingAddressErrors"
            >
              {{ shippingAddressErrors.organization }}
            </div>
          </div>
          <div class="md:w-1/2 w-full">
            <label class="address-form__label" for="phone"
              >Contact Number*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="phone"
              required
              v-model="customer.phone"
            />
            <input
              type="hidden"
              name="shippingAddress[fields][addressPhone]"
              v-model="customer.phone"
            />
            <input
              v-if="!copyShipping"
              type="hidden"
              name="billingAddress[fields][addressPhone]"
              v-model="customer.phone"
            />
            <div
              class="address-form__error"
              v-if="shippingAddressErrors || billingAddressErrors"
            >
              {{ shippingAddressErrors.addressPhone }}
            </div>
          </div>
        </div>
      </div>
    </fieldset>
    <div class="flex gap-8 md:gap-12 md:mt-12 mt-8 md:flex-row flex-col mb-4">
      <fieldset class="address-form__fields address-form__fields--addresses">
        <h3 class="address-form__headings">Shipping Address</h3>
        <div class="address-form__input-container">
          <label class="address-form__label" for="shippingAddress[address1]"
            >Address Line 1*</label
          >
          <input
            type="text"
            class="address-form__input"
            ref="shippingAddressOneField"
            name="shippingAddress[addressLine1]"
            v-model="shippingAddress.address1"
            required
          />
          <div class="address-form__error" v-if="shippingAddressErrors">
            {{ shippingAddressErrors.addressLine1 }}
          </div>
        </div>
        <div class="address-form__input-container">
          <label class="address-form__label" for="shippingAddress[address2]"
            >Address Line 2</label
          >
          <input
            type="text"
            class="address-form__input"
            name="shippingAddress[address2]"
            v-model="shippingAddress.address2"
          />
        </div>
        <div class="flex gap-4 justify-between md:justify-start">
          <div
            class="address-form__input-container address-form__input-container--flex"
          >
            <label class="address-form__label" for="shippingAddress[postalCode]"
              >Postcode*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="shippingAddress[postalCode]"
              v-model="shippingAddress.postCode"
              required
            />
            <div class="address-form__error" v-if="shippingAddressErrors">
              {{ shippingAddressErrors.postalCode }}
            </div>
          </div>
          <div
            class="address-form__input-container address-form__input-container--flex"
          >
            <label class="address-form__label" for="shippingAddress[countryCode]"
              >Country*</label
            >
            <select
              class="address-form__input address-form__input--select"
              id="country"
              @change="checkCountryId($event, 'shipping')"
              name="shippingAddress[countryCode]"
              v-model="shippingAddress.countryId"
              required
            >
              <option
                v-for="(country) in countries"
                :key="country.key"
                :value="country.key"
              >
                {{ country.title }}
              </option>
            </select>
            <div class="address-form__error" ref="shippingCountry"></div>
            <div class="address-form__error" v-if="shippingAddressErrors">
              {{ shippingAddressErrors.countryCode }}
            </div>
          </div>
        </div>
        <div class="flex gap-4 justify-between md:justify-start">
          <div
            class="address-form__input-container address-form__input-container--flex address-form__input-container--last"
          >
            <label class="address-form__label" for="shippingAddress[locality]"
              >Town / City*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="shippingAddress[locality]"
              v-model="shippingAddress.city"
              required
            />
            <div class="address-form__error" v-if="shippingAddressErrors">
              {{ shippingAddressErrors.locality }}
            </div>
          </div>
          <div
            class="address-form__input-container address-form__input-container--flex address-form__input-container--last"
          >
            <label class="address-form__label" for="shippingAddress[addressCounty]"
              >County*</label
            >
            <input
              type="text"
              class="address-form__input"
              name="shippingAddress[fields][addressCounty]"
              v-model="shippingAddress.county"
              required
            />
            <div class="address-form__error" v-if="shippingAddressErrors">
              {{ shippingAddressErrors.addressCounty }}
            </div>
          </div>
        </div>
      </fieldset>

      <fieldset class="address-form__fields address-form__fields--addresses">
        <div class="flex items-end justify-between mb-6 md:mb-10">

          <h3 class="address-form__headings address-form__headings--last">
            Billing Address
          </h3>
          <div class="flex xs:flex-row flex-col items-center xs:items-start">
            <label class="switch">
              <input
                type="checkbox"
                @click="toggleBillingBtn"
                :checked="copyShipping"
                autocomplete="off"
                name="billingAddressSameAsShipping"
                :value="copyShipping ? '1' : '0'"
              />
              <span class="slider round"></span>
            </label>
            <p class="text-xs p-0 ml-2 text-center xs:text-left">
              Same as shipping address
            </p>
          </div>
        </div>

        <div
          :class="{
            'opacity-50': copyShipping,
            'pointer-events-none': copyShipping,
          }"
        >
          <div class="address-form__input-container">
            <label class="address-form__label" for="billingAddress[addressLine1]"
              >Address Line 1*</label
            >
            <input
              type="text"
              class="address-form__input"
              ref="billingAddressOneField"
              name="billingAddress[addressLine1]"
              v-model="billingAddress.address1"
              required
              :disabled="copyShipping ? true : false"
            />
            <div class="address-form__error" v-if="billingAddressErrors">
              {{ billingAddressErrors.addressLine1 }}
            </div>
          </div>
          <div class="address-form__input-container">
            <label class="address-form__label" for="billingAddress[addressLine2]"
              >Address Line 2</label
            >
            <input
              type="text"
              class="address-form__input"
              name="billingAddress[addressLine2]"
              v-model="billingAddress.address2"
              :disabled="copyShipping ? true : false"
            />
          </div>
          <div class="flex gap-4 justify-between md:justify-start">
            <div
              class="address-form__input-container address-form__input-container--flex"
            >
              <label class="address-form__label" for="billingAddress[postalCode]"
                >Postcode*</label
              >
              <input
                type="text"
                class="address-form__input"
                name="billingAddress[postalCode]"
                v-model="billingAddress.postCode"
                required
                :disabled="copyShipping ? true : false"
              />
              <div class="address-form__error" v-if="billingAddressErrors">
                {{ billingAddressErrors.postalCode }}
              </div>
            </div>
            <div
              class="address-form__input-container address-form__input-container--flex"
            >
              <label class="address-form__label" for="billingAddress[countryCode]"
                >Country*</label
              >
              <select
                class="address-form__input address-form__input--select"
                id="country"
                name="billingAddress[countryCode]"
                @change="checkCountryId($event, 'billing')"
                v-model="billingAddress.countryId"
                required
                :disabled="copyShipping ? true : false"
              >
                <option
                  v-for="(country) in countries"
                  :key="country.key"
                  :value="country.key"
                >
                  {{ country.title }}
                </option>
              </select>
              <div
                class="address-form__error"
                ref="billingCountry"
                v-if="!copyShipping"
              ></div>
              <div class="address-form__error" v-if="billingAddressErrors">
                {{ billingAddressErrors.countryCode }}
              </div>
            </div>
          </div>
          <div class="flex gap-4 justify-between md:justify-start">
            <div
              class="address-form__input-container address-form__input-container--flex address-form__input-container--last"
            >
              <label class="address-form__label" for="billingAddress[locality]"
                >Town / City*</label
              >
              <input
                type="text"
                class="address-form__input"
                name="billingAddress[locality]"
                v-model="billingAddress.city"
                required
                :disabled="copyShipping ? true : false"
              />
              <div class="address-form__error" v-if="billingAddressErrors">
                {{ billingAddressErrors.locality }}
              </div>
            </div>
            <div
              class="address-form__input-container address-form__input-container--flex address-form__input-container--last"
            >
              <label class="address-form__label" for="billingAddress[fields][addressCounty]"
                >County*</label
              >
              <input
                type="text"
                class="address-form__input"
                name="billingAddress[fields][addressCounty]"
                v-model="billingAddress.county"
                required
                :disabled="copyShipping ? true : false"
              />
              <div class="address-form__error" v-if="billingAddressErrors">
                {{ billingAddressErrors.addressCounty }}
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </div>
    <p v-if="isDisabled" class="w-fit text-red-500 ml-auto">Please ensure all required fields (*) are filled out correctly.</p>
    <div class="mb-16 pt-4 w-full flex justify-between">
      <div class="text-left">
        <a class="btn block text-lg xs:text-xl w-auto" href="/basket">Back</a>
      </div>
      <div class="text-right">
          <button
            class="btn text-lg xs:text-xl w-auto hello"
            type="submit"
            :disabled="isDisabled ? true : undefined"
          >
            Review Your Order
          </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, watch, ref, nextTick, computed } from "vue";

const props = defineProps({
  shippingDetails: {
    required: true,
    validator: (val) => typeof val === "object" || val === null,
  },
  billingDetails: {
    required: true,
    validator: (val) => typeof val === "object" || val === null,
  },
  customerDetails: {
    required: true,
    validator: (val) => typeof val === "object" || val === null,
  },
  billingAddressErrors: {
    required: true,
    validator: (val) => typeof val === "object" || val === null,
  },
  shippingAddressErrors: {
    required: true,
    validator: (val) => typeof val === "object" || val === null,
  },
  emailAddressErrors: String,
  queryParam: String,
  countries: Array,
  defaultCountryId: String,
  sameAddress: String,
});

const shippingCountry = ref(null)
const billingCountry = ref(null)
const customerFirstNameField = ref(null)
const shippingAddressOneField = ref(null)
const billingAddressOneField = ref(null)
const copyShipping = ref(false)

const formSchema = {
  customerDetails: {
    firstName: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    lastName: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    company: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    email: {
      initialValue: "",
      validate: (val) => String(val)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    },
    phone: {
      initialValue: "",
      validate: (val) => val !== ""
    },
  },
  address: {
    address1: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    address2: {
      initialValue: "",
      validate: () => true
    },
    postCode: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    city: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    county: {
      initialValue: "",
      validate: (val) => val !== ""
    },
    countryId: {
      initialValue: props.defaultCountryId,
      validate: (val) => val == props.defaultCountryId
    }
  }
}

const defaultAddressState = Object.entries(formSchema.address).reduce((formState, fieldGroup) => {
    const [ field, { initialValue } ] = fieldGroup
    formState[field] = initialValue
    return formState
}, {});

const defaultCustomerState = Object.entries(formSchema.customerDetails).reduce((formState, fieldGroup) => {
    const [ field, { initialValue } ] = fieldGroup
    formState[field] = initialValue
    return formState
}, {});

// Form state
const customer = reactive( 
    props.customerDetails ? 
    Object.assign({}, props.customerDetails) : { ...defaultCustomerState }
);

const shippingAddress = reactive(props.shippingDetails 
  ? { ...props.shippingDetails } 
  : { ...defaultAddressState }
)

const billingAddress = computed(() => copyShipping.value ? shippingAddress : defaultBillingAddress)

const defaultBillingAddress = reactive(props.billingDetails 
  ? { ...props.billingDetails } 
  : { ...defaultAddressState }
)
// END Form state

const fullName = computed(() => customer.firstName + ' ' + customer.lastName)

onMounted(() => {
  switch (props.queryParam) {
    case "shipping":
      nextTick(() => shippingAddressOneField.value.focus());
      break;
    case "billing":
      nextTick(() => billingAddressOneField.value.focus());
      break;
    case "personal":
    default:
      nextTick(() => customerFirstNameField.value.focus());
      break;
  }
});
      
// copyShipping.value = !props.shippingId && !props.billingId ? false : props.shippingId === props.billingId ? true : false;
copyShipping.value =  props.sameAddress ? true : false

function toggleBillingBtn() {
  copyShipping.value = !copyShipping.value
}

// Checks if United Kingdom is selected and displays error message otherwise
function checkCountryId(event, type) {
    if (event.target.value != props.defaultCountryId && type == "shipping") shippingCountry.value.textContent = "United Kingdom only*";
    else if (event.target.value != props.defaultCountryId && type == "billing") billingCountry.value.textContent = "United Kingdom only*";
    else if (event.target.value == props.defaultCountryId && type == "shipping") shippingCountry.value.textContent = "";
    else if (event.target.value == props.defaultCountryId && type == "billing") billingCountry.value.textContent = "";
}

// Disables form submission if required form fields are invalid
const isDisabled = computed(() => {
  for (const fieldName in customer) {
    if (!formSchema.customerDetails.hasOwnProperty(fieldName)) continue;
    if (!formSchema.customerDetails[fieldName].validate(customer[fieldName])) return true;
  }

  for (const fieldName in shippingAddress) {
    if (!formSchema.address.hasOwnProperty(fieldName)) continue;
    if (!formSchema.address[fieldName].validate(shippingAddress[fieldName])) return true;
  }

  if (!copyShipping.value) {
    for (const fieldName in billingAddress.value) {
      if (!formSchema.address.hasOwnProperty(fieldName)) continue;
      if (!formSchema.address[fieldName].validate(billingAddress.value[fieldName])) return true;
    }
  }

  return false
})

// If same as shipping is not toggled set billing address to default state
watch(copyShipping, (shouldCopy) => {
  if (shouldCopy) return

  for (const field in defaultAddressState) {
    if (! defaultBillingAddress.hasOwnProperty(field)) continue;
    defaultBillingAddress[field] = defaultAddressState[field];
  }
})


</script>