<!-- Vue component -->

<template>
    <div ref="target">
        <transition>
            <div class="animated-component">
                <div class="square-info-box" ref="squareBox">
                    <div v-for="(block, key) in blockData">
                        <div class="p-1">
                            <div class="square-info-box__box" :class="{'square-info-box__hover': block.hovered}" @mouseover="block.hovered = true" @mouseleave="block.hovered = false">
                                <div class="relative w-full h-full flex-col">
                                    <div class="square-info-box__icon-wrapper">
                                        <div class="square-info-box__icon" v-html="block.icon"></div>
                                    </div>
                                    <div v-if="!block.hovered">{{ block.header }}</div>
                                    <div v-if="block.hovered" class="square-info-box__paragraph">{{ block.text }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'

const props = defineProps({
    data: Array
})

const blockData = ref([...props.data])
const target = ref();
const squareBox = ref()

const observer = new IntersectionObserver(
  ([entry]) => {
    if (entry.isIntersecting) inview()
  },
  {
    root: squareBox.value,
    threshold: 0,
  }
)

onMounted(() => {
    observer.observe(target.value)
})

function inview() {
    var secs = 500;
    blockData.value.forEach((block, id) => {
        setTimeout(() => {
            preview(id)
        }, secs);
        secs = secs + 200;
    })
}

function preview(id) {
    blockData.value[id].hovered = true;
    setTimeout(() => {
        blockData.value[id].hovered = false
    }, 3000)
}


</script>

<style lang="pcss">
   .square-info-box {
        @apply grid grid-cols-2 gap-1 pb-10 py-5 mx-4;

        &__box {
            -webkit-transition: background-color 500ms linear;
            -ms-transition: background-color 500ms linear;
            transition: background-color 500ms linear;
            @apply rounded-lg w-44 h-44 bg-white shadow-lg p-2 text-center flex items-center justify-center max-w-full;
        }

        &__icon-wrapper {
                @apply p-4 flex items-center w-full;  

            .square-info-box__icon {
                @apply bg-white w-12 h-12 p-2 rounded-full mx-auto text-primary-darker border-2 border-white;
                -webkit-transition: border-color 500ms linear;
                -ms-transition: border-color 500ms linear;
                transition: border-color 500ms linear;
            }
        }

        &__hover {
            @apply bg-primary-dark text-white;

            .square-info-box__icon-wrapper {
                @apply absolute mb-3;
                top:-32px;
            }

            .square-info-box__icon {
                @apply absolute top-0 -ml-6 border-2 border-primary-darker;
                left: 50%;
            }
            
            .square-info-box__paragraph {
                @apply text-xs pt-1 w-full h-full flex items-center justify-center;
                top:-30px;
            }
        }
    }

    @screen lg {
        .square-info-box {
            @apply grid grid-cols-3 gap-2 py-0;

            &__box {
                @apply w-40 h-40;
            }
        }
    }
</style>
