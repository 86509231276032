<template>
  <button class="w-full text-lg btn xs:text-xl xs:w-auto" @click="isModalVisible = true">Add Your Payment Details</button>
  
  <Modal :open="isModalVisible">
    <div class="modal__container">
      <!-- Modal heading -->
      <div class="modal__header">
        <h3 class="summary-container__heading" id="modalTitle">Payment Details</h3>
        <button @click="isModalVisible = false" class="text-xl cursor-pointer hover:text-primary">
          <font-awesome-icon icon="times"/>
        </button>
      </div>

      <!-- Modal body -->
      <div class="modal__body">
        <!-- Errors -->
        <slot name="error"></slot>

        <!-- Payment form -->
        <form
          method="POST"
          ref="stripeForm"
          class="payment-form"
          v-show="showPaymentForm"
        >
          <input type="hidden" :name="csrfName" :value="csrfToken" />
          <input type="hidden" name="orderEmail" :value="cartEmail" />

          <slot name="form"></slot>

          <div v-if="checkError" ref="cardErrorMsg" id="card-errors" role="alert" class="px-2 py-1 mt-8 text-red-500 bg-red-100 border border-red-500 rounded"></div>
          <button type="submit" class="w-full mt-10 text-xl btn md:text-2xl" :disabled="isSubmitting" ref="submitBtn">
            Complete Purchase
          </button>
        </form>

        <!-- T&Cs message -->
        <div class="text-xl" v-show="!showPaymentForm">
          Please accept our Terms and Conditions.
        </div>
      </div>
    </div>
  </Modal>
</template>

<script setup>
import Modal from './Modal.vue'
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import useEventsBus from '../useEventBus'

const props = defineProps({
  gatewayId: String,
  cartEmail: String,
  publishableKey: String,
  shippingAddress: Object,
  addressCounty: String,
  addressPhone: String,
  termsAndConditions: Boolean,
});

const isModalVisible = ref(false)
const tando= ref(false)
const isLoading = ref(false)
const isSubmitting = ref(false)
const checkError = ref(false)
const cardErrorMsg = ref('')
const submitBtn = ref('')
const stripeForm = ref(null)
const showPaymentForm = ref(false)

const { bus } = useEventsBus()

const csrfName = computed(() => {
  return window.csrfTokenName || "";
});

const csrfToken = computed(() => {
  return window.csrfTokenValue || "";
});

showPaymentForm.value = props.termsAndConditions

async function changeModalVisibility(val) {
  if (val) {
    try {
      isLoading.value = true
      const response = await fetch('/?action=commerce/cart/update-cart', {
        method: 'post',
        body: JSON.stringify({
          [csrfName.value]: csrfToken.value,
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const { cart } = await response.json()
      isAcceptTermsAndConditions.value = cart.acceptTermsAndConditions
      if (cart.acceptTermsAndConditions) {
        await nextTick(() => startStripe())
      }
      isLoading.value = false
      } catch (error) {
        console.error(error)
        isLoading.value = false
      }
    }
}

onMounted(startStripe)

watch(() => bus.value.get('update-terms-and-conditions'), (val) => {
    const [updateTermsAndConditions] = val ?? []
    showPaymentForm.value = updateTermsAndConditions
  }
)

function editStripeForm() {
  const paymentFormFields = document.querySelector(".payment-form-fields");
  paymentFormFields.classList.remove("hidden");

  const cardNameLegend = document.querySelector(".card-holder legend");
  cardNameLegend.textContent = "Cardholder's Name*";

  const cardDetailsTitle = document.createElement("h3");
  cardDetailsTitle.classList.add("card-details-title");
  cardDetailsTitle.textContent = "Card Details*";

  const stripeContainer = document.querySelector(".card-data");

  const cardLastName = document.querySelector(".card-holder-last-name");
  const cardFirstName = document.querySelector(".card-holder-first-name");
  cardLastName.parentNode.remove();
  cardFirstName.placeholder = "Name";
  cardFirstName.value = "";
  cardFirstName.parentNode.classList.add("card-holder-name");
  cardFirstName.parentNode.classList.remove("my-2", "md:w-1/2", "md:mx-4");
  cardFirstName.parentNode.parentNode.classList.remove("md:-mx-4");

  paymentFormFields.insertBefore(cardDetailsTitle, stripeContainer);
}

function startStripe() {
  editStripeForm();
  var stripe = Stripe(props.publishableKey);
  var elements = stripe.elements();
  var style = {
    base: {
      color: "black",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#474747",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  };

  const stripeFormRef = stripeForm.value;

  const cardFirstName = document.querySelector(".card-holder-first-name");
  const orderEmail = document.querySelector('input[name="orderEmail"');

  var card = elements.create("card", {
    style: style,
    hidePostalCode: true,
  });

  card.mount(".card-data");

  card.on("change", (event) => {
    if (event.error) {
      checkError.value = true;
      nextTick(() => cardErrorMsg.value.textContent = event.error.message);
    } 
    else if (cardFirstName.value != "") {
      checkError.value = false;
      cardFirstName.classList.remove("card-holder-first-name--invalid");
    } 
    else checkError.value = false;
  })

  let customerAddress = {
    line1: props.shippingAddress.addressLine1,
    city: props.shippingAddress.locality,
    postal_code: props.shippingAddress.postalCode,
    state: props.addressCounty,
    country: props.shippingAddress.countryCode
  };

  stripeFormRef.addEventListener("submit", (event) => {
    isSubmitting.value = true 
    submitBtn.value.textContent = "Submitting Payment"
    event.preventDefault();
    var paymentData = {
      billing_details: {
        email: orderEmail.value,
        name: cardFirstName.value,
        address: customerAddress,
        phone: props.addressPhone
      },
    }
    stripe
    .createPaymentMethod("card", card, paymentData)
    .then(result => {
      if (result.error) {
        checkError.value = true
        nextTick(() => cardErrorMsg.value.textContent = result.error.message)
        cardFirstName.classList.remove("card-holder-first-name--invalid");
        isSubmitting.value = false;
        submitBtn.value.textContent = "Complete Purchase"
      } else if (cardFirstName.value.trim() === "") {
          checkError.value = true;
          nextTick(() => cardErrorMsg.value.textContent = 'Please provide a name.')
          cardFirstName.classList.add("card-holder-first-name--invalid"); 
          isSubmitting.value = false;
          submitBtn.value.textContent = "Complete Purchase"
      } else {
        // Insert the token ID into the form so it gets submitted to the server
        var form = document.querySelector(".payment-form");
        var hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "paymentForm[stripe][paymentMethodId]"); // Craft Commerce only needs this
        hiddenInput.setAttribute("value", result.paymentMethod.id);
        form.appendChild(hiddenInput);

        form.submit();
      }
    })
  })
}

</script>