<template>
    <DependencyForm @updated="submitForm" @open-mobile="openMobileView">
        <template v-slot:dependents>
            <div class="md:flex-wrap flex-col md:flex-row" :class="{'hidden md:flex':!mobileOpen,'md:flex':mobileOpen}">
                <div class="flex w-full flex-wrap lg:flex-nowrap">
                    <SelectBoxDependent 
                        name="filter[brandId]"
                        dependent="filter[modelId]"
                        value-label="id"
                        ui-label="name"
                        placeholder="Make"
                        :options="brandList" 
                        :selected="selectedBrand" 
                    ></SelectBoxDependent>
                    <SelectBoxDependent
                        name="filter[modelId]"
                        dependent="filter[modelIdentifierID]"
                        value-label="id"
                        ui-label="name"
                        placeholder="Model"
                        :options="modelList"
                        :selected="selectedModel"
                    ></SelectBoxDependent>
                    <SelectBoxDependent
                        name="filter[modelIdentifierID]"
                        dependent="filter[year]"
                        value-label="id"
                        ui-label="name"
                        placeholder="Trim"
                        :options="trimList"
                        :selected="selectedTrim"
                    ></SelectBoxDependent>
                    <SelectBoxDependent
                        name="filter[year]"
                        dependent="filter[jobTypeId]"
                        value-label="id"
                        ui-label="label"
                        placeholder="Year"
                        :options="yearList"
                        :selected="selectedYear"
                    ></SelectBoxDependent>
                </div>
                    <SelectBoxDependent
                    name="filter[jobTypeId]"
                    value-label="id"
                    ui-label="name"
                    placeholder="Service"
                    :options="jobTypeList"
                    :selected="selectedJobType"
                    ></SelectBoxDependent>
            </div>
            <div class="p-2 md:hidden" v-if="hasFilter">
                <div class="text-primary-darker pl-1 pb-1 text-center">Current Filters</div>
                <div class="border-2 border-primary-darker p-2 pb-0 rounded-lg">
                    <ClearButtonDependent
                        name="filter[brandId]"
                        dependent="filter[modelId]"
                        :selected="selectedBrand"                  
                    ></ClearButtonDependent>
                    <ClearButtonDependent
                        name="filter[modelId]"
                        dependent="filter[modelIdentifierID]"
                        :selected="selectedModel"                  
                    ></ClearButtonDependent>
                    <ClearButtonDependent
                        name="filter[modelIdentifierID]"
                        dependent="filter[year]"
                        :selected="selectedTrim"                  
                    ></ClearButtonDependent>
                    <ClearButtonDependent
                        name="filter[year]"
                        dependent="filter[jobTypeId]"
                        :selected="selectedYear"                  
                    ></ClearButtonDependent>
                    <ClearButtonDependent
                        name="filter[jobTypeId]"
                        :selected="selectedJobType"                  
                    ></ClearButtonDependent>
                </div>
            </div>
        </template>
    </DependencyForm>
</template>

<script setup>
import { ref, nextTick, computed } from 'vue'
import DependencyForm from './DependencyForm.vue'
import SelectBoxDependent from './SelectBoxDependent.vue'
import ClearButtonDependent from './ClearButtonDependent.vue'

const mobileOpen = ref(false)

const props = defineProps({
    yearList: Array,
    selectedYear: [Array, Object],
    brandList: Array,
    selectedBrand: [Array, Object],
    modelList: Array,
    selectedModel: [Array, Object],
    trimList: Array,
    selectedTrim: [Array, Object],
    jobTypeList: Array,
    selectedJobType: [Array, Object]
})

function openMobileView(val) {
    mobileOpen.value = val
}

function submitForm(form) {
    nextTick(() => form.submit())
}

const hasFilter = computed(() => {
    return props.selectedBrand.name || props.selectedModel.name || props.selectedTrim.name || props.selectedJobType.name || props.selectedYear.label
})

</script>